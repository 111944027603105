<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-content>
        <div class="table-wrapper">
          <page-title :pageTitle="$t('user.create')" />
          <user-form :mutation="mutation" :submitAction="submitAction" :loading="loading" />
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import { CREATE_USER } from '@mdb/core/src';
import UserForm from './UserForm.vue';
import PageTitle from '@/components/text/PageTitle';
import { ref } from 'vue';
import Vue from 'vue';
import { router } from 'vue-router';
import { getCurrentInstance } from 'vue';
import { useFormMutation } from '@/components/helpers/useFormMutation';

export default {
  components: { UserForm, PageTitle },
  setup() {
    const { mutate, error, loading, onDone } = useFormMutation(CREATE_USER);

    const submitForm = async data => {
      // TODO: find a nicer way
      delete data.newPassword;
      delete data.confirmPassword;
      await mutate({ data: data });
    };
    return {
      submitAction: submitForm,
      loading
    };
  }
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
